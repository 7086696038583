@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* ------ ALL ------ */

html,
body {
  margin: 0;
  padding: 0;
  text-align: center;
  color: rgb(69, 69, 69);
  /* height: 100%; */
}

h2 {
  /* font-style: italic; */
}

* {
  font-family: "Poppins", sans-serif;
}

.primary-title-orig {
  /* font-size: 100px; */
  font-size: 80px;
  color: rgb(36, 36, 36);
  /* background-color: rgba(174, 81, 81, 0.8); */
  margin: 5px 20px 20px 20px;
  overflow-wrap: break-word;
  /* background-image: linear-gradient(
    to bottom,
    transparent 62%,
    #38e8ad 62%,
    #38e8ad 95%,
    transparent 95%
  ); */
  background-image: linear-gradient(
    to bottom,
    transparent 62%,
    #6addcf 62%,
    #6addcf 95%,
    transparent 95%
  );
  background-repeat: repeat-x;
  display: inline;
  /* padding: 10px; */
}

.primary-title {
  /* font-size: 100px; */
  font-size: 80px;
  color: rgb(36, 36, 36);
  /* background-color: rgba(174, 81, 81, 0.8); */
  margin: 5px 20px 20px 20px;
  overflow-wrap: break-word;
  /* background-image: linear-gradient(
    to bottom,
    transparent 62%,
    #38e8ad 62%,
    #38e8ad 95%,
    transparent 95%
  ); */
  background-image: linear-gradient(
    to bottom,
    transparent 62%,
    #6addcf 62%,
    #6addcf 95%,
    transparent 95%
  );
  background-repeat: repeat-x;
  display: inline;
  /* padding: 10px; */
}

.primary-button-orig {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 25px;
  /* ol3 */
  background: #7baabe;
  /* ol2 */
  /* background: #eb6e68; */
  /* other */
  /* background: linear-gradient(45deg, #ff8079, #ffa694); */
  /* background: linear-gradient(45deg, #67bdc5, #ff9393); */
  margin: 50px auto auto auto;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  display: block;
  /* margin: auto; */
}

.primary-button {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 25px;
  /* ol3 */
  background: #7baabe;
  /* ol2 */
  /* background: #eb6e68; */
  /* other */
  /* background: linear-gradient(45deg, #ff8079, #ffa694); */
  /* background: linear-gradient(45deg, #67bdc5, #ff9393); */
  margin-top: 20px;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
}

.primary-button:hover {
  /* ol3 */
  background: #81b6c8;
  /* ol2 */
  /* background: #e89894; */
  /* other */
  /* background: 
  linear-gradient(260deg, #ff6f6f, #ffb5b5); */
  /* background: linear-gradient(260deg, #67bdc5, #ff9393); */
}

.secondary-button {
  color: rgb(121, 119, 119);
  background-color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  padding: 12px 30px;
  border-radius: 30px;
  border: solid 2px rgb(121, 119, 119);
  margin: 6px;
}

.secondary-button:hover {
  color: rgb(48, 48, 48);
  border: solid 2px rgb(48, 48, 48);
}

/* ------ Navbar ------ */
nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* background-color: #fff; */
  /* margin-top: 30px; */
  /* padding-top: 30px; */
  padding-bottom: 20px;
}

nav .nav-button {
  color: rgb(255, 255, 255);
  background-color: #eb6e68;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  margin: 10px;
}

nav .nav-button:disabled {
  background-color: rgb(189, 180, 180);
  color: rgb(160, 38, 75);
}

nav .logo-container {
  width: 300px;
  /* margin: 30px; */
}

nav .logo-container .logo {
  width: 100%;
}

/* ------ Quiz Background ------ */
.quiz_background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(images/geo2.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* ------ Home page ------ */
.overlay {
  width: 100vw;
  height: 90vh;
  /* height: 1200px; */
  /* height: 150%; */
  /* position: fixed; */
  /* background: white; */
  /* background-image: url(images/fox.jpg); */
  background: 
  /* linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), */ url(images/3d.jpg);
  /* url(images/ol3.png); */
  /* background-size: cover; */
  background-size: contain;
  /* background-attachment: fixed; */
  background-position: bottom center;
  background-repeat: no-repeat;
  /* opacity: 50%; */
}

.home {
  /* margin-top: 30vw; */
}

/* ------ Auth Modal ------ */
.auth-modal {
  /* position: absolute; */
  /* position: absolute; */
  left: 0;
  right: 0;
  /* top: 50px; */
  /* top: 175px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  max-width: 360px;
  height: 500px;
  /* background-color: rgb(114, 162, 179); */
  background-color: rgb(167, 206, 219);
  border-radius: 10px;
  padding: 40px;
}

.auth-modal .close-icon {
  float: right;
}

.auth-modal form {
  display: flex;
  flex-direction: column;
}

.auth-modal input[type="password"],
.auth-modal input[type="email"] {
  padding: 8px;
  margin: 8px;
  font-size: 17px;
}

/* img {
  max-width: 100%;
  height: auto;
} */

/*-------- OnBoarding Page --------*/
.onboarding {
  border-top: solid 1px rgb(213, 213, 213);
}

.onboarding form {
  display: flex;
  justify-content: center;
}

.onboarding form section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 35%;
  text-align: start;
}

.onboarding form input {
  padding: 15px 30px;
  margin: 10px 0;
  font-size: 15px;
  border: solid 2px rgb(219, 219, 219);
  border-radius: 10px;
}

.onboarding form label {
  margin: 10px 0;
}

.onboarding form .multiple-input-container {
  display: flex;
  flex-direction: row;
}

.onboarding form input::-webkit-outer-spin-button,
.onboarding form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.onboarding form .multiple-input-container input {
  margin-right: 10px;
}

.onboarding form .multiple-input-container label {
  padding: 10px;
  border: solid 2px rgb(144, 144, 144);
  border-radius: 10px;
  transition: all 0.3s;
  margin-right: 10px;
}

.onboarding form input[type="radio"] {
  display: none;
}

.onboarding form input[type="number"] {
  width: 10%;
}

.onboarding form input[type="radio"]:checked + label {
  border: solid 2px rgb(221, 39, 22);
}

.onboarding form input[type="submit"]:hover {
  background-color: rgb(235, 235, 235);
}

.onboarding form input[type="submit"]:active {
  background-color: rgb(226, 115, 115);
}

.onboarding form .photo-container img {
  width: 100%;
}

/*-------- Dashboard  --------*/
.dashboard {
  display: flex;
  justify-content: space-between;
}

.dashboard .swipe-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.swipe {
  position: absolute;
}

.dashboard .card-container {
  width: 400px;
  height: 650px;
}

.card {
  width: 400px;
  height: 650px;
  border-radius: 30px;
  background-size: cover;
  background-position: center;
}

.card h3 {
  margin-top: 0;
}

.dashboard .swipe-info {
  position: absolute;
  bottom: 0;
  padding: 10px;
}

/*-------- Chat Container --------*/
.chat-container {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  width: 30%;
  text-align: left;
  z-index: 1;
}

.chat-container .chat-container-header {
  background: linear-gradient(45deg, rgb(254, 48, 114), rgb(255, 89, 64));
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-container .option {
  border: none;
  background-color: rgb(255, 255, 255);
  border-bottom: solid 4px rgb(243, 33, 33);
  font-size: 20px;
  margin: 2px;
  padding: 10px;
}

.chat-container .option:disabled {
  border-bottom: solid 4px rgb(187, 187, 187);
}

.chat-container .profile,
.chat-container .log-out-icon {
  display: flex;
  align-items: center;
  padding: 20px;
  color: rgb(255, 255, 255);
}

.img-container {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  margin: 10px;
}

.img-container img {
  width: 100%;
}

/*-------- Chat Display --------*/
.chat-display,
.matches-display {
  padding: 20px;
  height: 60vh;
  overflow-y: auto;
}

/*-------- Chat Input --------*/
.chat-input {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 850px) {
  .overlay {
    height: 80vh;
  }
}

@media (max-width: 600px) {
  .awsAmpAuth {
    width: 100%;
  }
  .primary-title {
    font-size: 3rem;
  }
  .nav-button {
    font-size: 15px !important;
    padding: 5px 10px !important;
  }
  .overlay {
    height: 80vh;
  }
}

@media (max-width: 375px) {
  .overlay {
    height: 100vh;
  }
}
